<template>
  <div style="width: 100%; height: 100%;">
    <transition>
      <router-view />
    </transition>
    <ScrollTop />
  </div>
</template>

<script>
import ScrollTop from "../components/ScrollTop.vue";
export default {
  components: { ScrollTop },
  created() {},
};
</script>

<style></style>
